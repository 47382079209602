import './docIcon.css'

function DocIcon() {
    return (
      <svg className="docIcon" viewBox="0 0 32 32">
        <path d="M16.707 0.707c-0.389-0.389-1.157-0.707-1.707-0.707h-12c-0.55 0-1 0.45-1 1v30c0 0.55 0.45 1 1 1h24c0.55 0 1-0.45 1-1v-18c0-0.55-0.318-1.318-0.707-1.707l-10.586-10.586zM26 30h-22v-28h10.974c0.091 0.015 0.263 0.086 0.338 0.14l10.549 10.549c0.054 0.075 0.125 0.247 0.14 0.338v16.974zM27 0h-6c-0.55 0-0.682 0.318-0.293 0.707l6.586 6.586c0.389 0.389 0.707 0.257 0.707-0.293v-6c0-0.55-0.45-1-1-1z"></path>
      </svg>
    )
  }

  export default DocIcon